<template>
  <div class="name-address-comp">
    <div class="NA-title">
      <h4>{{ title }}</h4>
      <p>
        {{ text }}
      </p>
    </div>
    <b-row>
       <b-col cols="12" lg="6" class="NA-input" v-for="(items, index) in nameAddress" :key="index">
        <label class="NA-input-label" :for="'items'+index">{{ items.titleText }}</label>
        <div><!---{{items.label}}-->
          <input
            :id="'items'+index"
            type="text"
            :placeholder="items.placeholderText"
             v-model="nameAddressData[items.label]"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "NameAddress",
  props: {
    title: {
      type: String,
      default: "Name and Address",
    },
    text: {
      type: String,
      default: "Use this section to enter user name and address details",
    },

    nameAddress: {
      type: Object,
      default() {
        return {};
      },
    },
  },
   beforeMount() {

     Object.values(this.nameAddress).forEach((val,key) => {
       // console.log(key, val)
        this.nameAddressData[val.label]=''
      });
      //console.log('NNN',this.nameAddressData)
   },
   mounted() {
      
    
  },

  data() {
    return {
      nameAddressData:{}
      
    };
  },
};
</script>

<style lang="scss">
$green:#1A544B;
.name-address-comp .NA-input .NA-input-label{
  margin-bottom: 7px;
}
.option-comments-comp .OP-comment textarea{
  border: 1px solid #1A544B !important;
  height: 150px;
  width: 100% !important;
}
.name-address-comp .NA-input input{
  width: 100% !important;
}
.name-address-comp {
  h4,p{
  color:#1A544B;
  }
  
  .NA-input {
    margin-bottom: 16px;
    .NA-input-label {
      font-size: 16px;
      color: $green;
      font-weight: bold;
    }
    input {
      width: 90%;
      padding: 16px;
      border: $green solid 1.6px;
      border-radius: 8px;
      outline: none;
      background: transparent;
      color: $green;

      &::-webkit-input-placeholder {
        color: rgba($green, 0.5);
      }
      &::-ms-input-placeholder {
        color: rgba($green, 0.5);
      }
      &:-ms-input-placeholder {
        color: rgba($green, 0.5);
      }
    }
  }
}
</style>