<template>
  <div class="radio-column-comp">
    <div>
      <p>{{questions.otherSelectionPlaceholderText}}</p>
       <p> {{questions.placeholderText}} </p>
    </div>
    <div class="radios">
      <div
        class="radio"
        v-for="(question, index) in questions.selectionOptions"
        :key="`${$route.name}_${$route.name}_radios_${id}_${index}`"
      >
        <div class="fl w100 radio-input">
          <div class="fl radioBox pr-2">
          <input
          :id="'radio-item'+index"
            type="radio"
            v-model="radioFeedback[0]"
            :value="question.labelText"
          />
          </div>
          <div class="fl w90 "><label :for="'radio-item'+index"><div class="fl">{{ question.placeholderText }}</div></label></div>
        </div>
        <div class="radio-textarea" v-if="question.isOther">
          <label class="DP-check d-none" for="other-description">Other Description</label>
          <textarea id="other-description" v-model="radioFeedback[1]"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioColumn",
  props: {
    id: {
      type: String,
      required: true,
    },
    titleText: {
      type: String,
      default: "Radio Title Text",
    },
    questions: {
      type: Array,
      default() {
        return [
          { value: "Radio1 Value", textbox: false },
          { value: "Radio2 Value", textbox: true },
        ];
      },
    },
    currentInput: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  beforeMount() {
    this.radioFeedback = this.currentInput;
  },
  data() {
    return {
      radioFeedback: ["", ""],
    };
  },
};
</script>

<style lang="scss">
.radio-column-comp {
  p {
    color: #27606c;
    font-weight: bold;
  }

  .radios {
    .radio {
      margin-bottom: 8px;
      .radio-input {
        span {
          margin-left: 15.5px;
        }
      }
      .radio-textarea {
        margin-top: 8px;
        textarea {
          margin-left: 28.5px;
          width: 40%;
          border: 1px solid #27606c;
          border-radius: 8px;
          outline: none;
          padding: 16px;

          @media screen and (max-width: 992px) {
            width: 90%;
            padding: 0;
          }
        }
      }
    }
  }
}
</style>