<template>
  <div class="data-protection-comp">
    <div>
      <p class="DP-title">{{ title }}</p>
    </div>
    <div v-for="(para, index) in text" :key="`${$route.name}_DPText_${index}`">
           <b-row>
              <b-col>
                  <h4>{{para.titleText}}</h4>
                  <p>{{ para.placeholderText }}</p>
              </b-col>
           </b-row>
          <b-row  v-if="para.checkBoxNoYes==true">
            <b-col cols="12">
              <div class="fl chk-wrap pr-2">
              <label class="DP-check d-none" for="DP-input-chekbox">{{ para.checkboxDescription }}</label>
              <input id="DP-input-chekbox" type="checkbox" v-model="dataProtection" />
              <span class="checkmark"></span>
              </div>
              <div class="fl w80">{{ para.checkboxDescription}}
                <p>{{resolveTickBox()}}</p>
              </div>
            </b-col>
          </b-row>
    </div> 
  </div>
</template>

<script>


import store from '../../store';
export default {
  name: "DataProtection",
  data() {
    return {
      dataProtection: false,
    };
  },
  beforeMount() {
    this.dataProtection = this.currentInput;
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    textonly: {
      type: Array,
      default() {
        return ["Lorem ipsum dolor sit amet, consectetur adipiscing elit."];
      },
    },
    text: {
      type: Array,
      default() {
        return ["Lorem ipsum dolor sit amet, consectetur adipiscing elit."];
      },
    },
    currentInput: {
      type: Object,
      default() {
        return false;
      },
    },
  },

  methods:{
    //THis resolves a bug in the CMS, need to be removed if 2 lines appear
    resolveTickBox(){
      if(store.state.language.name === "welsh")
      {
        return "Rwyf am gael fy diweddaru gyda gwybodaeth bellach";
      }
        
      return " ";
    }
  }
};
</script>

<style lang="scss">
.chk-wrap{
  margin-top: 2px;
}
$green:#1A544B;
.data-protection-comp{
          h4{
              font-size: 16px;
              font-weight: bold;
              color:  #1A544B;
          }
      }
.data-protection-comp {
  margin: 0 0 20px 0;
p{
  color:$green;
}
  .DP-title {
    color: #27606c;
    font-weight: bold;
  }

  [type="checkbox"]:not(:checked) {
    width: 17px;
    height: 17px;
    border: solid 1px $green;
    border-radius: 2px;
    background: #27606c;
  }

  [type="checkbox"]:checked {
    width: 17px;
    height: 17px;
    border: solid 1px $green;
    border-radius: 2px;
    background: #27606c;
  }
}
</style>