<template>
  <div class="option-comments-comp">
    <div class="OP-title" v-if="title">{{ textTitle }}</div>
    <div
      class="OP-comment"
      v-for="(option, index) in options"
      :key="`${$route.name}_optioncomments${id}_${index}`"
    >
      <div class="OP-comment-title" :for="'items'+index">{{ option.titleText }}</div>
      <div>
        <textarea
        :id="'items'+index"
          v-model="feedBackData[index]"
          :placeholder="option.placeholderText"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptionComments",
  props: {
    id: {
      type: String,
      required: true,
    },
    textTitle: {
      type: String,
      default: "Option comment Title",
    },
    title: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default() {
        return [{ optionDetail: "Option details here" }];
      },
    },
    currentInput: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  beforeMount() {
    this.feedBackData = this.currentInput;
  },
  data() {
    return {
      feedBackData: [],
    };
  },
};
</script>

<style lang="scss">
$green:#1A544B;
.option-comments-comp {
  .OP-title {
    color: $green;
    font-weight: bold;
  }

  .OP-comment {
    margin: 16px 0;

    .OP-comment-title {
      color: $green;
      font-weight: bold;
    }

    textarea {
      width: 95%;
      border: 2px solid $green;
      border-radius: 8px;
      outline: none;
      padding: 16px;
      margin-top: 4px;
      background: transparent;
      color: $green;

      &::-webkit-input-placeholder {
        color: rgba($green, 0.5);
      }
      &::-ms-input-placeholder {
        color: rgba($green, 0.5);
      }
      &:-ms-input-placeholder {
        color: rgba($green, 0.5);
      }

      @media screen and (max-width: 992px) {
        width: 90%;
        padding: 0;
      }
    }
  }
}
</style>